<template>
  <Heading noDecoration text-align="center">Shipping</Heading>

  <div class="shipping-wrapper">
    <div class="shipping-details">
      <div v-for="[topic, list] in Object.entries(parsedShipping)" :key="topic">
        <Heading noDecoration type="h4" class="subheader">
          {{ topic }}
        </Heading>
        <ul style="max-width: 951px">
          <li
            v-for="(item, index) in list"
            :key="item.slice(0, 5) + index"
            class="timing__list-item"
          >
            {{ item }}
          </li>
        </ul>
      </div>
    </div>

    <div class="banner-container" v-if="shipping.shipping_address">
      <div class="banner">
        <div class="banner-title">Shipping Address</div>
        <div class="banner-description">
          {{ shipping.shipping_address && shipping.shipping_address[0] }}
        </div>

        <div class="actions">
          <Tooltip
            :text="copied ? 'Address copied to clipboard' : 'Click to copy'"
            class="mt-5"
            style="display: block"
          >
            <Button @click="copyCode()"> Copy Address </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Heading from "@/components/common/Heading";
import Tooltip from "@/components/common/Tooltip";
import Button from "@/components/common/Button";

export default {
  name: "Shipping",
  props: {
    shipping: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    Heading,
    Tooltip,
    Button,
  },
  data: () => ({
    copied: false,
  }),
  computed: {
    parsedShipping() {
      // eslint-disable-next-line no-unused-vars
      const { shipping_address, ...rest } = this.shipping;
      return rest;
    },
  },
  methods: {
    async copyCode() {
      try {
        await navigator.clipboard.writeText(this.shipping.shipping_address[0]);
        this.copied = true;
        setTimeout(() => {
          this.copied = false;
        }, 2000);
      } catch ($e) {
        console.log("Cannot copy");
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";
.heading--h4 {
  text-transform: uppercase;
  margin-top: 0;
}
/*@import "@/assets/scss/_variables";

.subheader {
  margin: 21px 0 10px;

  @media (min-width: $media-sm) {
    margin: 26px 0 22px;
  }
  @media (min-width: $media-lg) {
    margin: 66px 0 22px;
  }
}*/

.banner {
  padding: 16px;
  margin-bottom: 17px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 10px;
  max-width: 619px;
  order: 1;
  min-width: 466px;

  &-title {
    font-family: $Oswald;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    line-height: 36px;
    padding-bottom: 24px;
    letter-spacing: 0.05em;
  }

  &-description {
    margin-top: 24px;
  }
}

.shipping-wrapper {
  display: flex;
  margin-top: 64px;
  column-gap: 16px;
}
</style>
