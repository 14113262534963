<template>
  <Heading noDecoration text-align="center"> Call for papers</Heading>
  <div class="mt-18" style="max-width: 952px">
    <p class="mb-10">
      {{ eventName }} invites industry experts in IT Security and/or Hacking to
      submit talks and engage with a very interested audience.
    </p>
    <p class="mb-10">
      Potential speakers will be required to fill in their bio and contact
      details, talk title, description, and select a conference track. If you
      are creating a submission for somebody else please make sure to specify
      who will be presenting.
    </p>
    <p class="mb-12">
      After receiving your submission, we’ll reach out to confirm if your talk
      has been approved.
    </p>
  </div>
  <div class="btn-wrapper">
    <Button white class="px-12" @click="copyURL">
      {{ copied ? "copied" : "copy link" }}
    </Button>
    <Button class="px-12" @click="$router.push(`/${$route.params.id}/cfp`)">
      Go to the form
    </Button>
  </div>
</template>

<script>
import Heading from "@/components/common/Heading";
import Button from "@/components/common/Button";

export default {
  name: "CFPInfo",
  props: {
    eventName: {
      type: String,
      default: "",
    },
  },
  components: { Heading, Button },
  data: () => ({
    copied: false,
  }),
  computed: {},
  methods: {
    async copyURL() {
      const link =
        process.env.VUE_APP_BASE_URL.split("api/")[0] +
        "#/" +
        this.$route.params.id +
        "/cfp";
      try {
        await navigator.clipboard.writeText(link);
        this.copied = true;
        setTimeout(() => {
          this.copied = false;
        }, 2000);
        // ("Link copied to clipboard")
      } catch ($e) {
        console.log("Cannot copy");
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

p {
  text-align: justify;
  letter-spacing: 0.02em;
  font-family: $Lato;
}

.btn-wrapper {
  display: flex;
  flex-wrap: wrap;

  button {
    width: 100%;
  }

  button:first-child {
    margin-bottom: 16px;
  }

  @media (min-width: $media-sm) {
    flex-wrap: nowrap;
    button {
      width: auto;
    }
    button:first-child {
      margin-bottom: 0;
      margin-right: 24px;
    }
  }
}
</style>
