<template>
  <Heading noDecoration text-align="center" class="title">Schedule</Heading>
  <div
    v-for="(item, index) in schedules"
    v-bind:key="index + item.start_time"
    class="timing"
  >
    <div class="timing__date padding-x">
      <span>{{ getDate(item.start_time) }}</span>
    </div>
    <div class="timing__details">
      <div class="timing__wrapper">
        <div class="timing__time">
          {{ getTimeInterval(item) }}
        </div>
        <span class="timing__topic">{{ item.scheduleItem.topic }}</span>
      </div>
      <ul>
        <li
          v-for="(item, index) in item.scheduleItem.list"
          :key="item + index"
          class="timing__list-item"
        >
          {{ item }}
        </li>
      </ul>
    </div>
  </div>
  <div
    v-for="(facility, index) in kit.facility"
    v-bind:key="index"
    class="facility-container"
  >
    <Heading noDecoration type="h4" class="mb-6">
      {{ facility.title }}
    </Heading>
    <div class="facility">
      <img :src="facility.image" :alt="facility.title" class="facility__img" />
      <div v-html="facility.description" class="facility__descr" />
    </div>
  </div>
</template>

<script>
import Heading from "@/components/common/Heading";
import moment from "moment";
export default {
  name: "General",
  props: {
    kit: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { Heading },
  data: () => ({}),
  computed: {
    schedules() {
      if (this.kit.exhibitor_schedule) {
        return this.kit.exhibitor_schedule.map((item) => {
          let scheduleItem = {};
          if (item.schedule_item) {
            const [topic, list] = Object.entries(item.schedule_item)[0];
            console.log(topic, list, item.schedule_item);
            scheduleItem = { topic, list };
          }

          return {
            ...item,
            scheduleItem,
          };
        });
      }

      return [];
    },
  },
  methods: {
    getDate(date) {
      if (!date) {
        return "";
      }
      const startDate = new Date(date);
      return moment(startDate).format("dddd, Do MMMM YYYY");
    },
    getTime(date) {
      const startDate = new Date(date);
      return startDate.toLocaleString("en-US", {
        hour12: true,
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    getTimeInterval(sched) {
      const { start_time, end_time } = sched;
      if (!start_time || !end_time) {
        return "";
      }

      return `${this.getTime(start_time)} - ${this.getTime(end_time)}`;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.title {
  padding-bottom: 30px;
}

.timing {
  margin: 0 -24 px;

  &__date {
    font-family: $Oswald;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.05em;
    color: #ed1c24;
    border: 3px solid #ed1c24;
    box-sizing: border-box;
    border-radius: 10px;
    span {
      padding: 10px 0;
      display: block;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    margin: 22px 0 44px 0;
  }

  &__wrapper {
    margin-bottom: 20px;
  }

  &__time {
    font-family: $Oswald;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0.05em;
    color: #ffffffcc;
  }

  &__topic {
    font-family: $Oswald;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 0.05em;
    color: #ffffff;
    margin-top: 12px;
    text-transform: uppercase;
  }

  ul {
    li {
      font-family: Lato;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: justify;
      letter-spacing: 0.02em;
      color: #ffffff;
    }
  }

  &__list-topic {
    color: $dark-gray;
    font-weight: $sansBold;
  }

  &__list-item {
    display: list-item;
    list-style-type: disc;
  }
}

.facility-container {
  border: 2px solid rgba(255, 255, 2550, 0.1);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 16px;

  .facility {
    display: flex;
    flex-wrap: wrap;

    &__img {
      width: 100%;
      max-width: 575px;
      height: fit-content;
    }

    &__descr {
      margin-top: 15px;
      margin-left: 24px;
      position: relative;

      &:after {
        content: "";
        width: 2px;
        height: 40%;
        background: $dark-gray;
        position: absolute;
        top: -25%;
        left: -3%;
      }
    }
  }
}

.heading--h4 {
  text-transform: uppercase;
}

@media (min-width: $media-xs) {
  .timing {
    &__time {
      min-width: 100px;
    }
  }
}

@media (min-width: $media-sm) {
  .title {
    padding-bottom: 56px;
  }
  .timing {
    &__time {
      min-width: 135px;
    }
    &__details {
      flex-direction: row !important;
    }

    &__wrapper {
      width: 300px;
    }
  }
  .facility {
    flex-wrap: nowrap;

    &__img {
      width: 65%;
    }

    &__descr {
      &:after {
        width: 40%;
        height: 2px;
        top: 0;
        left: -40%;
      }
    }
  }
}

@media (min-width: $media-md) {
  .timing {
    margin: 0 -24 px;

    &__date {
      font-family: $Oswald;
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 47px;
      letter-spacing: 0.05em;
      color: #ed1c24;
      border: 3px solid #ed1c24;
      box-sizing: border-box;
      border-radius: 10px;
      width: calc(100vw - 15%);
      height: 79px;
      span {
        padding: 10px 0;
        display: block;
      }
    }

    &__wrapper {
      margin-right: 185px;
    }

    &__time {
      font-family: Oswald;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 0.05em;
      color: #ffffffcc;
    }

    &__topic {
      font-family: $Oswald;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 0.05em;
      color: #ffffff;
      margin-top: 12px;
      text-transform: uppercase;
    }

    ul {
      li {
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        text-align: justify;
        letter-spacing: 0.02em;
        color: #ffffff;
      }
    }

    &__list-topic {
      color: $dark-gray;
      font-weight: $sansBold;
    }

    &__list-item {
      display: list-item;
      list-style-type: disc;
    }
  }

  .facility-container {
    border: 2px solid rgba(255, 255, 2550, 0.1);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 48px;

    .facility {
      display: flex;
      flex-wrap: wrap;

      &__img {
        width: 100%;
        max-width: 575px;
        height: fit-content;
      }

      &__descr {
        margin-top: 15px;
        margin-left: 24px;
        position: relative;

        &:after {
          content: "";
          width: 2px;
          height: 40%;
          background: $dark-gray;
          position: absolute;
          top: -25%;
          left: -3%;
        }
      }
    }
  }

  .heading--h4 {
    text-transform: uppercase;
  }
}
@media (min-width: $media-lg) {
  .title {
    padding-bottom: 85px;
  }
  .timing {
    &__date {
      margin: 0 -22%;
    }

    &__time {
      min-width: 172px;
    }
  }
}
</style>
