<template>
  <div class="kit__tabs__blur">
    <nav class="padding-x">
      <router-link to="/">
        <img :src="logoPath" alt="Exploit logo" class="nav__logo" />
      </router-link>
    </nav>
  </div>
  <div class="kit">
    <Preloader v-if="isKitLoading" :loading="isKitLoading" class="mt-15" />
    <div class="kit__tabs">
      <div
        @click.prevent="setActiveTab('general')"
        :class="{ active: isTabActive('general') }"
      >
        General info
      </div>
      <div
        @click.prevent="setActiveTab('participants')"
        :class="{ active: isTabActive('participants') }"
      >
        Participants
      </div>
      <div
        @click.prevent="setActiveTab('codes')"
        :class="{ active: isTabActive('codes') }"
      >
        Codes
      </div>
      <!--      <div
        @click.prevent="setActiveTab('drawings')"
        :class="{ active: isTabActive('drawings') }"
      >
        Drawings
      </div>-->
      <div
        @click.prevent="setActiveTab('accommodation')"
        :class="{ active: isTabActive('accommodation') }"
      >
        Accommodation
      </div>
      <div
        @click.prevent="setActiveTab('cfp')"
        :class="{ active: isTabActive('cfp') }"
      >
        CFP
      </div>
      <div
        @click.prevent="setActiveTab('shipping')"
        :class="{ active: isTabActive('shipping') }"
      >
        Shipping
      </div>
      <div
        @click.prevent="setActiveTab('profile')"
        :class="{ active: isTabActive('profile') }"
      >
        Update Profile
      </div>
    </div>
    <div v-if="kit" class="kit__content padding-x">
      <div v-show="isTabActive('general')">
        <General :kit="kit" />
      </div>
      <div v-show="isTabActive('codes')" class="kit__codes">
        <Error v-if="codesError">
          <template #heading>
            Sorry, something went wrong while loading codes
          </template>
          <template #body>
            Please try again or contact us at
            <a :href="`mailto:${config.helpEmail}`" class="error__link">
              {{ config.helpEmail }}
            </a>
          </template>
        </Error>
        <div v-else>
          <Heading noDecoration text-align="center" class="kit__codes-title">
            Codes</Heading
          >
          <div class="kit__codes-block">
            <SponsorCodesFAQ
              class="pr-5"
              v-if="codes"
              :company="codes[0].organization"
            />
            <SponsorCodes :tickets="codes" :loading="isOrderLoading" />
          </div>
        </div>
      </div>
      <div v-show="isTabActive('drawings')">
        <Heading noDecoration class="kit__codes-title">Drawings</Heading>
        <Preloader :loading="isDrawingsLoading" />
        <h3 v-show="!isDrawingsLoading && !drawings">
          Sorry, you don't seem to have any drawings yet
        </h3>
        <div v-for="draw in drawings" :key="draw.id">
          <Drawing :drawing="draw" @winner="getDrawings" />
        </div>
      </div>
      <div v-show="isTabActive('participants')" class="kit__participants">
        <Heading noDecoration text-align="center" class="kit__codes-title">
          Participants
        </Heading>
        <Preloader :loading="isRegistrationsLoading" />
        <h3 v-show="!isRegistrationsLoading && !registrations">
          Sorry, you can’t see registrations yet
        </h3>
        <Participants
          v-if="registrations && registrations.length"
          :data="registrations"
          :date="event.start"
          :eventName="event.name"
        />
      </div>
      <div v-show="isTabActive('accommodation')">
        <AccommodationInfo :accommodations="kit.accommodation" />
      </div>
      <div v-show="isTabActive('cfp')">
        <CFPInfo :eventName="event.name" />
      </div>
      <div v-show="isTabActive('shipping')">
        <Shipping :shipping="kit.exhibitor_instructions" />
      </div>
      <div v-show="isTabActive('profile')">
        <UpdateProfile :sponsor="sponsor" :sponsorLoading="sponsorLoading" />
      </div>
    </div>
    <Error v-if="kitError">
      <template #heading> Something went wrong </template>
      <template #body>
        Please try again or contact us at
        <a :href="`mailto:${config.helpEmail}`" class="error__link">
          {{ config.helpEmail }}
        </a>
      </template>
    </Error>
    <!-- <div class="kit__panel padding-x">
      <Heading noDecoration type="h4" class="mb-6">
        How to manage your sponsorship?
      </Heading>
      <p>
        For fast managing your sponsorship you can use sponsor panel. Using this
        panel you can:
      </p>

      <ul>
        <li>Manage sponsorship information</li>
        <li>Add company description</li>
        <li>Upload advertising videos and images</li>
      </ul>
      <a :href="adminUrl" style="text-decoration: none">
        <Button class="mt-12 px-12"> Go to sponsor panel</Button>
      </a>
    </div> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Preloader from "@/components/common/Preloader";
import Heading from "@/components/common/Heading";
import Error from "@/components/common/Error";
// import Button from "@/components/common/Button";
import SponsorCodesFAQ from "./SponsorCodesFAQ";
import CFPInfo from "./CFPInfo";
import AccommodationInfo from "./AccommodationInfo";
import Shipping from "./Shipping";
import SponsorCodes from "./SponsorCodes";
import General from "./General";
import Drawing from "./Drawing";
import Participants from "./Participants";
import UpdateProfile from "./UpdateProfile";
import config from "../../../public/config.json";

export default {
  name: "ExhibitorKit",
  props: {},
  components: {
    // Button,
    Participants,
    UpdateProfile,
    Error,
    General,
    SponsorCodes,
    Drawing,
    Shipping,
    AccommodationInfo,
    CFPInfo,
    Preloader,
    Heading,
    // Button,
    SponsorCodesFAQ,
  },
  data: () => ({
    config,
    activeTab: "general",
    isKitLoading: false,
    isRegistrationsLoading: false,
    isOrderLoading: false,
    isDrawingsLoading: false,
    kit: null,
    registrations: null,
    kitError: null,
    codes: null,
    codesError: null,
    drawings: null,
    sponsor: {},
    sponsorLoading: false,
    showBlur: false,
  }),
  computed: {
    ...mapGetters({
      event: "getCurrentEventDetails",
    }),

    logoPath() {
      return require("@/assets/img/logo.png");
    },
    adminUrl() {
      return process.env.VUE_APP_ADMIN_PANEL_URL;
    },
    // sponsorData() {
    //   return ["summary", "website", "description", "logo"].reduce(
    //     (prev, curr) => {
    //       prev[curr] = this.sponsor[curr];
    //       return prev;
    //     },
    //     {}
    //   );
    // },
  },
  mounted() {
    if (history.state.forward) {
      this.activeTab = "general"; //for redirect with browser "back" arrow
    } else if (
      history.state.back &&
      (history.state.back.includes("invite") ||
        history.state.back.includes("register-multiple"))
    ) {
      this.activeTab = "codes";
    } else {
      this.activeTab = "general";
    }

    // for getting an event name
    if (
      !Object.keys(this.event).length ||
      this.event.id !== this.$route.params.id
    ) {
      this.isKitLoading = true;
      this.getEvent(this.$route.params.id)
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isKitLoading = false;
        });
    }

    this.isKitLoading = true;
    this.isOrderLoading = true;
    this.isRegistrationsLoading = true;
    this.getRegistrations();
    this.getKitInfo();
    this.getOrder();
    // this.getDrawings();
    this.getSponsor();
  },
  methods: {
    ...mapActions(["GET", "getEvent"]),
    getRegistrations() {
      return this.GET({
        route: `/ticketing/public/event/${this.$route.params.id}/order/${this.$route.params.token}/registrations`,
      })
        .then((resp) => {
          // console.log("get Registrations", resp.data);
          this.registrations = resp.data;
          this.isRegistrationsLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isRegistrationsLoading = false;
        });
    },
    getKitInfo() {
      return this.GET({
        route: `/public/event/${this.$route.params.id}/exhibitor_kit`,
      })
        .then((resp) => {
          console.log(resp.data, "asdasd");
          // console.log("get kit", resp.data);
          this.kit = resp.data;
          this.isKitLoading = false;
        })
        .catch((err) => {
          console.log(err, "qweqw");
          this.kitError = true;
          this.isKitLoading = false;
        });
    },
    getOrder() {
      // for testing:
      // c5757583796fb73021b88c800ee3db1fc091fcb7b307b56ccc16009b13c66f3b990a4532a3c09bfe9d34b637757608aa799b7d95b0b39352b5a8e5e293592c7d
      return this.GET({
        route: `/ticketing/public/event/${this.$route.params.id}/order/${this.$route.params.token}`,
      })
        .then((resp) => {
          // console.log("get Order__", resp.data);
          this.codes = resp.data;
          this.isOrderLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.codesError = true;
          this.isOrderLoading = false;
        });
    },
    getSponsor() {
      return this.GET({
        route: `/public/event/${this.$route.params.id}/exhibitor_kit/${this.$route.params.token}`,
      })
        .then(({ data }) => {
          console.log("sponsor object", data.name, data.logo);
          this.sponsor = data;
          this.sponsorLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.sponsorLoading = false;
        });
    },
    getDrawings() {
      // for testing: 61e7e403050510414e2d9c35ff568540de98e9adb98dc6be7c945aa6656a57aed5624d99933d2ef1bb6be9bcce6db8342316f7b324b0f64d4d588a66f73d17e1
      this.isDrawingsLoading = true;
      return this.GET({
        route: `/event/${this.$route.params.id}/exhibitor_kit/${this.$route.params.token}/drawing`,
      })
        .then(({ data }) => {
          // console.log("drawings", data);
          this.drawings = data;
          this.isDrawingsLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isDrawingsLoading = false;
        });
    },
    isTabActive(menuItem) {
      return this.activeTab === menuItem;
    },
    setActiveTab(menuItem) {
      this.activeTab = menuItem;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

nav {
  padding-top: 16px;
  padding-bottom: 16px;

  .nav__logo {
    width: 137px;
  }

  @media (min-width: $media-sm) {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  @media (min-width: $media-lg) {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

.kit {
  padding-top: 70px;

  &__tabs {
    position: fixed;
    top: 72px;
    width: 100%;
    z-index: 5;
    background-color: $black;
    border-top: 1px solid $dark-gray;
    border-bottom: 1px solid $dark-gray;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &__blur {
      width: 100vw;
      height: 72px;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.4);
      backdrop-filter: blur(10px);
      z-index: +1;

      @media (min-width: $media-md) {
        height: 110px;
      }
    }

    div {
      cursor: pointer;
      padding: 10px;
      font-size: 14px;
      color: $dark-gray;
      text-transform: uppercase;
      border-top: 5px solid $black;
      border-bottom: 5px solid $black;

      font-family: Oswald;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 0.05em;
      color: $white;
    }
  }

  &__tabs .active {
    color: #ed1c24;
    border-top: 5px solid #ed1c24;
    border-bottom: 5px solid #ed1c24;
  }

  &__content {
    padding-top: 135px;
    padding-bottom: 35px;
  }

  &__codes {
    &-title {
      margin-bottom: 28px;
      font-family: $Oswald;
      font-weight: 600;
      font-size: 72px;
      line-height: 107px;
      letter-spacing: 0.05em;
      color: #ffffff;
    }

    &-block {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__panel {
    padding-top: 100px;
    padding-bottom: 120px;
    background-color: #202020;

    .heading--h4 {
      font-family: $Oswald;
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 56px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #ffffff;
    }

    p,
    ul li {
      font-family: $openSans;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 32px;
      letter-spacing: 0.05em;
      color: #ffffff;
    }
  }

  @media (min-width: $media-xs) {
    &__content {
      padding-top: 85px;
    }
  }

  @media (min-width: $media-sm) {
    padding-top: 122px;
    &__tabs {
      top: 109px;
    }
    &__codes {
      &-title {
        margin-bottom: 56px;
      }

      &-block {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  @media (min-width: $media-md) {
    &__tabs {
      div {
        font-size: 24px;
        padding: 8px 35px;
      }
    }

    &__panel {
      .heading--h4 {
        font-family: $Oswald;
        font-style: normal;
        font-weight: 600;
        font-size: 72px;
        line-height: 107px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #ffffff;
      }

      p,
      ul li {
        font-family: $openSans;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 32px;
        letter-spacing: 0.05em;
        color: #ffffff;
      }
    }
  }

  @media (min-width: $media-lg) {
    &__tabs {
      div {
        padding: 8px 50px;
      }
    }

    &__content {
      padding-top: 129px;
      padding-bottom: 136px;
    }

    &__participants {
      margin: 0 -10%;
    }

    &__codes {
      &-title {
        margin-bottom: 87px;
      }
    }
  }
}
</style>
